import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ChooseWinner,
  GetCurrentRoomStats,
  colorOption,
} from "../../../ApiServices/Apifun";
import { useLocation } from "react-router-dom";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { AiFillEye } from "react-icons/ai";
import { Pagination, Popconfirm, Skeleton, Table } from "antd";
import { FaUserAlt } from "react-icons/fa";
import ColorProfileModal from "./ProfileModal";
import { dispatchtoast } from "../../../Utils";
import { GiPodiumWinner } from "react-icons/gi";

const CurrentRoomStats = () => {
  const location = useLocation();
  const { game_type } = location.state || {};
  const dispatch = useDispatch();
  const [RoomDetail, setRoomDetail] = useState({});
  const [bettingDetail, setBettingDetail] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [current, setCurrent] = useState(1);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [profileDetail, setProfileDetail] = useState(null);
  const [colorOptions, setColorOption] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);
  const RoomStatistics = () => {
    // setShowSkeleton(true);
    // setLoading(true);
    GetCurrentRoomStats(`?room_type=${game_type}&page=${current}&limit=10`)
      .then((res) => {
        if (res.status) {
          // dispatchtoast(res.message);
          const {
            room_bet_close_time,
            room_close_time,
            // room_game_slug,
            room_id,
            room_start_time,
            room_status,
            winner_option,
          } = res.roomDetail;
          const data = {
            "Room Id": room_id,
            "Bet Close Time": new Date(room_bet_close_time)
              .toLocaleString()
              .split(",")
              .join(" "),
            "Room Close Time": new Date(room_close_time)
              .toLocaleString()
              .split(",")
              .join(" "),
            "room Start Time": new Date(room_start_time)
              .toLocaleString()
              .split(",")
              .join(" "),
            "Room Status": room_status ? "Active" : "In Active",
            "Winner Option":
              winner_option == "0" ? "Not Declared" : winner_option,
          };
          setRoomDetail(data);
          setTotalPages(res.pagination.totalBettingDocuments);
          setBettingDetail(
            res.bettingDetails.map((i, index) => {
              return {
                ...i,
                sno: index + 1,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
    // .finally(() => {
    //   setShowSkeleton(false);
    //   setLoading(false);
    // });
  };
  const getColorOption = () => {
    colorOption()
      .then((res) => {
        if (res.status) {
          setColorOption(res.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    dispatch(setselectedTab("2"));
    dispatch(setHeading(" Current Room Stats"));
    getColorOption();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      RoomStatistics();
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Choosen Option",
      dataIndex: "choose_option_id",
      key: "choose_option_id",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-2">
          <p
            className={`text-center ${
              record?.result?.option_name === "Red"
                ? "bg-red-600 text-white"
                : record?.result?.option_name === "Voilet"
                ? "bg-violet-700 text-white"
                : record?.result?.option_name === "Green"
                ? "bg-green-600 text-white"
                : "text-black"
            } rounded-md p-1`}
          >
            {record?.result?.option_name}
          </p>
        </div>
      ),
    },
    // {
    //   title: "Game Name",
    //   dataIndex: "game_slug",
    //   key: "game_slug",
    // },
    {
      title: "Bet Amount",
      dataIndex: "bet_amount",
      key: "bet_amount",
      render: (text) => <p>{text ?? "N/A"} Rs.</p>,
    },
    {
      title: "Bet Type",
      dataIndex: "bettype",
      key: "bettype",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Plateform service amount",
      dataIndex: "platform_service_amt",
      key: "platform_service_amt",
      render: (text) => <p>{text ?? "N/A"} Rs.</p>,
    },
    {
      title: "Winning Amount",
      dataIndex: "winning_amount",
      key: "winning_amount",
      render: (text) => <p>{text} Rs.</p>,
    },
    {
      title: "View Profile",
      dataIndex: "txn.",
      key: "txn.",
      render: (text, record) => (
        <p
          onClick={() => {
            setProfileDetail(record.UserDetails);
            setOpenProfileModal(true);
          }}
        >
          <AiFillEye
            className="bg-primary text-white flex justify-center items-center p-2"
            size={25}
          />
        </p>
      ),
    },
    {
      title: "Choose Winner",
      dataIndex: "txn.",
      key: "txn.",
      render: (text, record) => (
        <Popconfirm
          description={
            <div className="grid grid-cols-5 gap-x-4  place-items-center">
              {colorOptions.map((i, index) => (
                <p
                  onClick={() => setSelectedOption(i.id)}
                  className={`${
                    selectedOption === i.id
                      ? "bg-green-800 px-2 text-white"
                      : ""
                  } cursor-pointer`}
                >
                  {i.option_name}
                </p>
              ))}
            </div>
          }
          onConfirm={() =>
            ChooseWinner({
              winner_option: selectedOption,
              room_id: record.room_id,
            })
              .then((res) => {
                if (res.status) {
                  dispatchtoast(res.message);
                }
              })
              .catch((err) => console.log(err))
          }
          icon={null}
          okText="Set"
          okButtonProps={{ className: "bg-primary" }}
          placement="leftBottom"
          title={"Set Winner"}
        >
          <p>
            <GiPodiumWinner
              className="bg-primary text-white flex justify-center items-center p-2"
              size={25}
            />
          </p>
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      {showSkeleton ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 place-items-center">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <div className="  h-[100px]   w-full">
              <Skeleton.Button
                active={true}
                size={200}
                shape={"default"}
                block={false}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 place-items-center">
          {Object.keys(RoomDetail).length !== 0 ? (
            Object.keys(RoomDetail).map((key, index) => {
              return (
                <div
                  key={index}
                  className=" shadow-lg hover:bg-gray-400 transition-all cursor-pointer shadow-gray-300 bg-white p-4 w-full"
                >
                  <p className="flex justify-center items-center font-bold text-md text-primary">
                    {RoomDetail[key]}
                  </p>
                  <div className="flex text-lg mt-4 justify-between items-center">
                    <p className="font-semibold text-sm capitalize">
                      {key.split(/(?=[A-Z])/).join(" ")}
                    </p>
                    <FaUserAlt />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-center items-center">
              <p className="font-bold">No Detail Found</p>
            </div>
          )}
        </div>
      )}
      <div className="bg-primary mt-4 p-4 text-white font-bold rounded-sm">
        <p>Option & it's stats</p>
      </div>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={bettingDetail}
        />
        <div className="mt-2 mb-8 flex justify-end items-center">
          <Pagination
            showQuickJumper={false}
            defaultCurrent={current}
            pageSize={10}
            current={current}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
      {openProfileModal && (
        <ColorProfileModal
          open={openProfileModal}
          setOpen={setOpenProfileModal}
          object={profileDetail}
        />
      )}
    </>
  );
};

export default CurrentRoomStats;
